/** @jsx jsx */
import { jsx, Box, Heading, Flex } from 'theme-ui';

const SectionHeading = ({ title, description, ...props }) => {
  return (
    <Box sx={styles.heading} {...props}>
      <Flex sx={{alignItems: 'center', justifyContent: 'space-between'}}>
        <Heading sx={styles.title}>{title}</Heading>
        <Box sx={styles.horizonBar}></Box>
      </Flex>
    </Box>
  );
};

export default SectionHeading;

const styles = {
  heading: {
    textAlign: 'left',
    // maxWidth: 580,
    marginLeft: 0,
    marginBottom: 30,
  },
  title: {
    color: 'heading',
    backgroundImage: `-webkit-linear-gradient(left, #416585 0%, #FF8B81 50%)`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontWeight: 500,
    fontSize: ['50px', '80px'],
    lineHeight: [1.25, 1.5],
    letterSpacing: ['-2px', 'heading'],
  },
  horizonBar: {
    maxWidth: ['100px', '240px', '356px'],
    width: '100%',
    height: ['5px', '12px', '16px'],
    background: 'linear-gradient(279.39deg, #ADD8FF -2.28%, #FF8B81 83.47%)',
    mt: '4px'
  }
};
