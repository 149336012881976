/** @jsx jsx */
import { jsx } from 'theme-ui';

const PlusIcon = ({...props}) => {
    return (
        <svg {...props} sx={styles.icon} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 13H32V19H0V13Z" fill={props.fill}/>
            <path d="M19 2.62268e-07L19 32H13L13 0L19 2.62268e-07Z" fill={props.fill}/>
        </svg>
    )
}

export default PlusIcon

const styles = {
    icon: {
        marginLeft: 'auto',
        transition: 'transform 0.6s ease',
        position: 'absolute',
        right: ['8px', '34px'],
        width: ['15px', '32px'],
        height: ['15px', '32px'],
    },
}